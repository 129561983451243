<template>
  <div>
    <strong class="my-2">{{name}}</strong>
    <div :id="id" :style="`width: ${width}; height: ${height};`"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { v4 as uuid } from 'uuid'
import theme from '@/libs/colorTheme'
echarts.registerTheme('kpmg', theme)

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  components: {
  },
  props: ['data', 'name', 'width', 'height'],
  computed: {
  },
  created: async function () {
    await delay(10)
    this.load()
  },
  data () {
    return {
      id: uuid()
    }
  },
  methods: {
    load: async function () {
      const options = {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove'
        },
        series: [
          {
            type: 'tree',
            data: [this.data],
            top: '1%',
            left: '7%',
            bottom: '1%',
            right: '20%',
            symbolSize: 7,
            label: {
              position: 'left',
              verticalAlign: 'middle',
              align: 'right',
              fontSize: 9
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left'
              }
            },
            emphasis: {
              focus: 'descendant'
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750,
            animationThreshold: 2000000
          }
        ]
      }
      await delay(10)
      echarts.init(document.getElementById(this.id), 'kpmg').setOption(options)
    },
    reload: function () {
      console.log('triggered reload')
      echarts.dispose(document.getElementById(this.id))
      this.load()
    }
  },
  watch: {
    'data': 'reload',
    'name': 'reload'
  }
}
</script>

<style>
</style>
