<template>
<div>
  <b-container fluid>
    <b-row class="py-4">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Graphical tree lineage</h1>
        <p>Select a framework to build graph.</p>
      </b-col>
    </b-row>
    <b-row class="my-4 py-4 bg-white shadow">
      <b-col>
        <strong>Select framework:</strong><br />
        <b-select v-model="framework" :options="frameworksOptions" @change="calculateTree" placeholder="Select framework"></b-select>
      </b-col>
    </b-row>
    <b-row class="my-4 py-4 bg-white shadow" v-if="calculatingTree">
      <b-col class="text-center">
        <b-spinner label="Calculating lineage tree"></b-spinner><br />
        calculating lineage tree
      </b-col>
    </b-row>
    <b-row class="my-4 py-4 bg-white shadow" v-if="!calculatingTree && tree">
      <b-col>
        <tree-simple :data="tree" name="Lineage" width="100%" height="1500px"></tree-simple>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import delay from '@/libs/delay'
import treeSimple from '@/components/charts/v1/treeSimple.vue'

export default {
  name: 'Lineage',
  components: {
    treeSimple
  },
  computed: {
    dataelements: function () {
      return this.$store.state.dataelements
    },
    frameworks: function () {
      return this.$store.state.reportingframeworks
    },
    frameworksOptions: function () {
      return this.frameworks.map(framework => {
        return {
          value: framework,
          text: framework.name
        }
      })
    },
    metrics: function () {
      return this.$store.state.metrics
    }
  },
  data () {
    return {
      calculatingTree: false,
      framework: null,
      pillar: null,
      tree: false
    }
  },
  created: function () {
    document.title = "ESG Metrics"
  },
  methods: {
    calculateTree: async function () {
      this.calculatingTree = true
      await delay(20)
      const results = {
        name: this.framework.name,
        children: []
      }
      const filteredMetricsFramework = this.metrics.filter(metric => metric.reportingframeworks.some(row => row.id === this.framework.id))
      let pillars = []
      filteredMetricsFramework.forEach(metric => {
        metric.taxonomy.forEach(taxonomy => {
          taxonomy.parents.forEach(parent => {
            pillars.push(parent)
          })
        })
      })
      pillars = _.orderBy(_.uniqBy(pillars, 'id'), ['name'], ['asc'])
      pillars.forEach(pillar => {
        results.children.push({
          name: pillar.name,
          id: pillar.id,
          children: []
        })
      })
      results.children.forEach(pillar => {
        const filteredMetricsPillar = filteredMetricsFramework.filter(metric => metric.taxonomy.some(taxonomy => taxonomy.parents.some(parent => parent.id === pillar.id)))
        let taxonomy = []
        filteredMetricsPillar.forEach(metric => {
          metric.taxonomy.forEach(taxonomyRow => {
            taxonomy.push(taxonomyRow)
          })
        })
        taxonomy = _.orderBy(_.uniqBy(taxonomy, 'id'), ['name'], ['asc'])
        pillar.children = taxonomy.map(taxonomy => {
          return {
            name: taxonomy.name,
            id: taxonomy.id,
            children: []
          }
        })
        pillar.children.forEach(taxonomy => {
          const filteredMetricsTaxonomy = filteredMetricsPillar.filter(metric => metric.taxonomy.some(taxonomyRow => taxonomyRow.id === taxonomy.id))
          let metricsX = []
          filteredMetricsTaxonomy.forEach(metric => {
            metricsX.push({
              name: metric.name,
              id: metric.id
            })
          })
          metricsX = _.orderBy(_.uniqBy(metricsX, 'id'), ['name'], ['asc'])
          taxonomy.children = metricsX.map(metric => {
            return {
              name: _.truncate(metric.name, { length: 70 }),
              id: metric.id,
              children: []
            }
          })
          taxonomy.children.forEach(metric => {
            // find metric in filteredMetricsTaxonomy
            const metricX = filteredMetricsTaxonomy.find(row => row.id === metric.id)
            metric.children = metricX.dataelements.map(dataelement => {
              return {
                name: _.truncate(dataelement.name, { length: 70 }),
                id: dataelement.id,
                children: []
              }
            })
            metric.children.forEach(dataelement => {
              const dataelementX = this.dataelements.find(row => row.id === dataelement.id)
              dataelement.children = dataelementX.metrics.map(metric => {
                return {
                  name: _.truncate(metric.name, { length: 70 }),
                  id: metric.id,
                  children: []
                }
              })
              dataelement.children.forEach(metric => {
                const metricX = this.metrics.find(row => row.id === metric.id)
                metric.children = metricX.reportingframeworks.map(framework => {
                  return {
                    name: framework.name,
                    id: framework.id
                  }
                })
              })
            })
          })
        })
      })
      this.tree = results
      this.calculatingTree = false
    }
  }
}
</script>

<style>
</style>
